import { StaticChildData, childrenLeftAfter2022 } from "./StaticData/childrenData";
import { getLanguage } from "./storageHelper";

const previousChildrenLeftBefore2023 = process.env.REACT_APP_SHANDE_CHILDREN_LEFT_BEFORE_2023;
const previousChildrenLeftAfter2023 = process.env.REACT_APP_SHANDE_CHILDREN_LEFT_AFTER_2023;
const shandeAddress = process.env.REACT_APP_SHANDE_ADDRESS;
const shandeEmail = process.env.REACT_APP_SHANDE_EMAIL;
const shandeFacebook = process.env.REACT_APP_SHANDE_FACEBOOK;
const shandeFacebookName = process.env.REACT_APP_SHANDE_FACEBOOK_NAME;

export function sliceDataIntoChunks(arr, chunkSize = 4) {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk = arr.slice(i, i + chunkSize);
        res.push(chunk);
    }
    return res;
  }

export function SplitCommaString(words) {
    // console.log(words)
    if(words && typeof(words) == "string" && words.includes(",")) {
        const wordsArray = words?.split(",");
        return (
          <ul className="ul-no-format">
            {wordsArray.map((word) => (
              <li key={word}>{word}</li>
            ))}
          </ul>
        );
    }
    else {
        return words;
    }
  }

export function SplitBRString(words) {
  // console.log(words)
  if(words && typeof(words) == "string" && words.includes("<br />")) {
      const wordsArray = words?.split("<br />");
      return (
        <ul className="ul-no-format">
          {wordsArray.map((word) => (
            <li key={word}>{word}</li>
          ))}
        </ul>
      );
  }
  else {
      return words;
  }
}

export function replaceExceptFirst(str) {
  if (str.length <= 1) {
    return str; // No need to replace if the string has only one character or is empty
  }

  const words = str.split(' ');
  const replacedWords = words.map(word => {
    if (word.length <= 1) {
      return word; // No need to replace if the word has only one character or is empty
    }
    const firstChar = word.charAt(0);
    const xs = 'x'.repeat(word.length - 1);
    return firstChar + xs;
  });

  return replacedWords.join(' ');
}

export function calculateAge(dob) {
  const currentYear = new Date().getFullYear();
  const birthYear = new Date(dob).getFullYear();

  return currentYear - birthYear;
}

export function genderFullName(genderShortName = "M") {
  const language = getLanguage();
  if(language == 1) {
    switch(genderShortName) {
      case "M": return "男";
      case "F": return "女";
      default: return "男";
    }
  }
  else {
    switch(genderShortName) {
      case "M": return "Male";
      case "F": return "Female";
      default: return "Male";
    }
  }
}

export function getStaticStaffNameAndNumber(position = null) {
  const staff = [
    // {
    //   name: "Klinsmon",
    //   contact: "0175993211",
    //   position: "Marketing"
    // },
    // {
    //   name: "Ice",
    //   contact: "0178363211",
    //   position: "Marketing"
    // },
    {
      name: "Jasmine",
      contact: "0169384491",
      position: "Vice President"
    },
    {
      name: "Sammi",
      contact: "0166663211",
      position: "President"
    },
    {
      name: "Simon",
      contact: "0177621191",
      position: "Vice President"
    },
    {
      name: "Jen",
      contact: "0143211781",
      position: "Marketing"
    },
    {
      name: "Dean",
      contact: "0187927282",
      position: "Marketing"
    },
  ]
  if(!position) {
    return staff;
  }
  else {
    const filteredData = staff.filter(item => item.position == position);
    return filteredData;
  }
}

export function getCurrentShandeAge() {
  const today = new Date();
  const currentYear = today.getFullYear();
  const shandeYear = 2011;
  return currentYear - shandeYear;
}

export function getShanDeChildrenAmount() {
  let shanDeChildrenAmount = 0;
  // if($child != null) {
  //     $num = count($child);
  //     return $num;
  // }
  // else return 0;

  shanDeChildrenAmount = StaticChildData().length;
  //if call api data != null, show api data
  return parseInt(shanDeChildrenAmount);
}

export function getShanDeTotalHelpedChildrenAmount() {
  //94 children already left before 17 August 2022
  const previousChildrenLeft = parseInt(previousChildrenLeftBefore2023);
  const previousChildrenLeft2 = parseInt(previousChildrenLeftAfter2023);
  const childrenLeftArrObj = childrenLeftAfter2022();
  const childrenLeftAmount = childrenLeftArrObj.length;

  // call api to get all children amount
  const total = previousChildrenLeft + previousChildrenLeft2 + getShanDeChildrenAmount() + childrenLeftAmount;
  return total;
}

export function getSimonNameAndPhoneNumber() {
  return "Simon (017-7621191)";
}

export function replaceStringWithShandeAge(inputString) {
  const replacedString = inputString.replace(/\[shandeAge\]/g, getCurrentShandeAge());
  return replacedString;
}

export function replaceStringWithShandeChildAmount(inputString) {
  const replacedString = inputString.replace(/\[shandeCurrentChildAmount\]/g, getShanDeChildrenAmount());
  return replacedString;
}

export function replaceStringWithShandeTotalHelpedChildAmount(inputString) {
  const replacedString = inputString.replace(/\[shandeTotalHelpedChildrenAmount\]/g, getShanDeTotalHelpedChildrenAmount());
  return replacedString;
}

export function getShandeAddressWithHref() {
  const address = <a target="_blank" rel="noreferrer" href = "https://goo.gl/maps/j4KHxiREaofG971Z6">{shandeAddress}</a>
  return address;
}

export function getShandeContactWithHref1() {
  const contact = <a href = "tel:073333211">07-333 3211</a>
  return contact;
}

export function getShandeContactWithHref2() {
  const contact = <a href = "tel:073383898">07-338 3898</a>
  return contact;
}

export function getShandeEmailWithHref() {
  const email = <a href = {`mailto:${shandeEmail}`}>{shandeEmail}</a>
  return email;
}

export function getShandeFacebookWithHref() {
  const facebook = <a target="_blank" rel="noreferrer" href = {shandeFacebook}>{shandeFacebookName}</a>
  return facebook;
}

export function getRandomStaffData(data, position = "Marketing") {
  const MarketingStaff = getStaticStaffNameAndNumber(position);
  // Generate a random index
  const randomIndex = Math.floor(Math.random() * MarketingStaff.length);

  // Get the random element from the array
  const randomData = MarketingStaff[randomIndex];
  if(data == "Contact") {
    return randomData?.contact;
  }
  else {
    return randomData;
  }
}

