const photo_url = process.env.REACT_APP_SHANDE_PHOTO_URL;
export function StaticChildData() {
    const childData = [
      {
        "id": "SDC23001",
        "shandeId": "SD-01",
        "chineseName": "程凯荔",
        "firstName": "Charlene Tiang",
        "lastName": "Kai Lee",
        "gender": "F",
        "dob": "2019-09-29",
        "pictureLocation": photo_url + "child_img/1.png",
        "base64Data": "assets/img/childPic/1.png"
      },
      {
        "id": "SDC23003",
        "shandeId": "SD-03",
        "chineseName": "黄宇宣",
        "firstName": "Nicole Wong",
        "lastName": "Yu Xuan",
        "gender": "F",
        "dob": "2019-07-28",
        "pictureLocation": photo_url + "child_img/3.png",
        "base64Data": "assets/img/childPic/3.png"
      },
      {
        "id": "SDC23006",
        "shandeId": "SD-07",
        "chineseName": "洪瑞恩",
        "firstName": "Ang",
        "lastName": "Lui En",
        "gender": "F",
        "dob": "2015-02-08",
        "pictureLocation": photo_url + "child_img/7.png",
        "base64Data": "assets/img/childPic/7.png"
      },
      {
        "id": "SDC23007",
        "shandeId": "SD-10",
        "chineseName": "黄昊添",
        "firstName": "Lashley Ng",
        "lastName": "Hao Tian",
        "gender": "M",
        "dob": "2015-08-06",
        "pictureLocation": photo_url + "child_img/10.png",
        "base64Data": "assets/img/childPic/10.png"
      },
      {
        "id": "SDC23008",
        "shandeId": "SD-11",
        "chineseName": "陈嘉德",
        "firstName": "Tan",
        "lastName": "Jia De",
        "gender": "M",
        "dob": "2015-01-07",
        "pictureLocation": photo_url + "child_img/11.png",
        "base64Data": "assets/img/childPic/11.png"
      },
      {
        "id": "SDC23009",
        "shandeId": "SD-12",
        "chineseName": "程雪荔",
        "firstName": "Cheryl Tiang",
        "lastName": "Xue Li",
        "gender": "F",
        "dob": "2014-07-09",
        "pictureLocation": photo_url + "child_img/12.png",
        "base64Data": "assets/img/childPic/12.png"
      },
      {
        "id": "SDC23010",
        "shandeId": "SD-14",
        "chineseName": "谢诗云",
        "firstName": "Sieh",
        "lastName": "Shi Yun",
        "gender": "F",
        "dob": "2014-03-16",
        "pictureLocation": photo_url + "child_img/14.png",
        "base64Data": "assets/img/childPic/14.png"
      },
      {
        "id": "SDC23011",
        "shandeId": "SD-15",
        "chineseName": "洪瑞祥",
        "firstName": "Ang",
        "lastName": "Rui Siang",
        "gender": "M",
        "dob": "2014-02-13",
        "pictureLocation": photo_url + "child_img/15.png",
        "base64Data": "assets/img/childPic/15.png"
      },
      {
        "id": "SDC23012",
        "shandeId": "SD-16",
        "chineseName": "卢妤雯",
        "firstName": "Yvonne Law",
        "lastName": "Ee Wen",
        "gender": "F",
        "dob": "2014-09-09",
        "pictureLocation": photo_url + "child_img/16.png",
        "base64Data": "assets/img/childPic/16.png"
      },
      {
        "id": "SDC23013",
        "shandeId": "SD-17",
        "chineseName": "陈伟明",
        "firstName": "Tan",
        "lastName": "Wei Ming",
        "gender": "M",
        "dob": "2014-03-06",
        "pictureLocation": photo_url + "child_img/17.png",
        "base64Data": "assets/img/childPic/17.png"
      },
      {
        "id": "SDC23015",
        "shandeId": "SD-19",
        "chineseName": "杨嘉乐",
        "firstName": "Yong",
        "lastName": "Jia Le",
        "gender": "M",
        "dob": "2013-05-13",
        "pictureLocation": photo_url + "child_img/19.png",
        "base64Data": "assets/img/childPic/19.png"
      },
      {
        "id": "SDC23016",
        "shandeId": "SD-21",
        "chineseName": "张宝晶",
        "firstName": "Arianna Lindan",
        "lastName": "Anak Keyo",
        "gender": "F",
        "dob": "2013-01-29",
        "pictureLocation": photo_url + "child_img/21.png",
        "base64Data": "assets/img/childPic/21.png"
      },
      {
        "id": "SDC23017",
        "shandeId": "SD-23",
        "chineseName": "谢静莹",
        "firstName": "Sieh",
        "lastName": "Jing Ying",
        "gender": "F",
        "dob": "2012-03-23",
        "pictureLocation": photo_url + "child_img/23.png",
        "base64Data": "assets/img/childPic/23.png"
      },
      {
        "id": "SDC23018",
        "shandeId": "SD-24",
        "chineseName": "",
        "firstName": "Yuvanesh",
        "lastName": "Ruth",
        "gender": "F",
        "dob": "2012-07-28",
        "pictureLocation": photo_url + "child_img/24.png",
        "base64Data": "assets/img/childPic/24.png"
      },
      {
        "id": "SDC23019",
        "shandeId": "SD-25",
        "chineseName": "程可荔",
        "firstName": "Chloe Tiang",
        "lastName": "Ko Li",
        "gender": "F",
        "dob": "2012-10-24",
        "pictureLocation": photo_url + "child_img/25.png",
        "base64Data": "assets/img/childPic/25.png"
      },
      {
        "id": "SDC23020",
        "shandeId": "SD-26",
        "chineseName": "黄浩轩",
        "firstName": "Stanley Ng",
        "lastName": "Hao Xuan",
        "gender": "M",
        "dob": "2011-06-03",
        "pictureLocation": photo_url + "child_img/26.png",
        "base64Data": "assets/img/childPic/26.png"
      },
      {
        "id": "SDC23021",
        "shandeId": "SD-27",
        "chineseName": "卢妤瑄",
        "firstName": "Mavis Law",
        "lastName": "Yu Shyen",
        "gender": "F",
        "dob": "2011-11-13",
        "pictureLocation": photo_url + "child_img/27.png",
        "base64Data": "assets/img/childPic/27.png"
      },
      {
        "id": "SDC23022",
        "shandeId": "SD-28",
        "chineseName": "杜金宝",
        "firstName": "Toh",
        "lastName": "Jin Paul",
        "gender": "M",
        "dob": "2011-02-17",
        "pictureLocation": photo_url + "child_img/28.png",
        "base64Data": "assets/img/childPic/28.png"
      },
      {
        "id": "SDC23023",
        "shandeId": "SD-29",
        "chineseName": "杨嘉恩",
        "firstName": "Yong",
        "lastName": "Jia En",
        "gender": "F",
        "dob": "2011-08-18",
        "pictureLocation": photo_url + "child_img/29.png",
        "base64Data": "assets/img/childPic/29.png"
      },
      {
        "id": "SDC23024",
        "shandeId": "SD-30",
        "chineseName": "卢为杰",
        "firstName": "Loo",
        "lastName": "Wei Jie",
        "gender": "M",
        "dob": "2011-12-01",
        "pictureLocation": photo_url + "child_img/30.png",
        "base64Data": "assets/img/childPic/30.png"
      },
      {
        "id": "SDC23025",
        "shandeId": "SD-31",
        "chineseName": "陈建锋",
        "firstName": "Chan",
        "lastName": "Jian Feng",
        "gender": "M",
        "dob": "2011-08-13",
        "pictureLocation": photo_url + "child_img/31.png",
        "base64Data": "assets/img/childPic/31.png"
      },
      {
        "id": "SDC23026",
        "shandeId": "SD-33",
        "chineseName": "卢俊皓",
        "firstName": "Louis Law",
        "lastName": "Jun How",
        "gender": "M",
        "dob": "2010-10-18",
        "pictureLocation": photo_url + "child_img/33.png",
        "base64Data": "assets/img/childPic/33.png"
      },
      {
        "id": "SDC23027",
        "shandeId": "SD-34",
        "chineseName": "",
        "firstName": "Yuvanesh",
        "lastName": "William",
        "gender": "M",
        "dob": "2010-12-07",
        "pictureLocation": photo_url + "child_img/34.png",
        "base64Data": "assets/img/childPic/34.png"
      },
      {
        "id": "SDC23028",
        "shandeId": "SD-35",
        "chineseName": "张国兴",
        "firstName": "Keong",
        "lastName": "Koon Ing",
        "gender": "M",
        "dob": "2010-01-16",
        "pictureLocation": photo_url + "child_img/35.png",
        "base64Data": "assets/img/childPic/35.png"
      },
      {
        "id": "SDC23029",
        "shandeId": "SD-37",
        "chineseName": "沈俊宇",
        "firstName": "Sim",
        "lastName": "Jun Yu",
        "gender": "M",
        "dob": "2009-11-29",
        "pictureLocation": photo_url + "child_img/37.png",
        "base64Data": "assets/img/childPic/37.png"
      },
      {
        "id": "SDC23031",
        "shandeId": "SD-39",
        "chineseName": "杜金源",
        "firstName": "Toh",
        "lastName": "Jin Yuan",
        "gender": "M",
        "dob": "2009-10-03",
        "pictureLocation": photo_url + "child_img/39.png",
        "base64Data": "assets/img/childPic/39.png"
      },
      {
        "id": "SDC23032",
        "shandeId": "SD-41",
        "chineseName": "胡芯蕾",
        "firstName": "Aw",
        "lastName": "Xin Lui",
        "gender": "F",
        "dob": "2009-09-04",
        "pictureLocation": photo_url + "child_img/41.png",
        "base64Data": "assets/img/childPic/41.png"
      },
      {
        "id": "SDC23033",
        "shandeId": "SD-42",
        "chineseName": "陈沛慈",
        "firstName": "Chan",
        "lastName": "Pei Ci",
        "gender": "F",
        "dob": "2008-12-08",
        "pictureLocation": photo_url + "child_img/42.png",
        "base64Data": "assets/img/childPic/42.png"
      },
      {
        "id": "SDC23034",
        "shandeId": "SD-43",
        "chineseName": "卢妤涵",
        "firstName": "Fion Law",
        "lastName": "Yi Han",
        "gender": "F",
        "dob": "2008-08-24",
        "pictureLocation": photo_url + "child_img/43.png",
        "base64Data": "assets/img/childPic/43.png"
      },
      {
        "id": "SDC23035",
        "shandeId": "SD-44",
        "chineseName": "段月圆",
        "firstName": "Joceline Teen",
        "lastName": "Yen Yuan",
        "gender": "F",
        "dob": "2008-01-08",
        "pictureLocation": photo_url + "child_img/44.png",
        "base64Data": "assets/img/childPic/44.png"
      },
      {
        "id": "SDC23039",
        "shandeId": "SD-56",
        "chineseName": "黄蔷薇",
        "firstName": "Wong",
        "lastName": "Qiang Wei",
        "gender": "F",
        "dob": "2016-08-17",
        "pictureLocation": photo_url + "child_img/56.png",
        "base64Data": "assets/img/childPic/56.png"
      },
      {
        "id": "SDC23040",
        "shandeId": "SD-57",
        "chineseName": "黄木兰",
        "firstName": "Wong",
        "lastName": "Mu Lan",
        "gender": "F",
        "dob": "2019-07-16",
        "pictureLocation": photo_url + "child_img/57.png",
        "base64Data": "assets/img/childPic/57.png"
      },
      {
        "id": "SDC23041",
        "shandeId": "SD-58",
        "chineseName": "黄凤銮",
        "firstName": "Wong",
        "lastName": "Fong Luan",
        "gender": "F",
        "dob": "2021-06-25",
        "pictureLocation": photo_url + "child_img/58.png",
        "base64Data": "assets/img/childPic/58.png"
      },
      {
        "id": "SDC23042",
        "shandeId": "SD-59",
        "chineseName": "王甜莹",
        "firstName": "Ong",
        "lastName": "Tian Ying",
        "gender": "F",
        "dob": "2019-01-05",
        "pictureLocation": photo_url + "child_img/59.png",
        "base64Data": "assets/img/childPic/59.png"
      },
      {
        "id": "SDC23043",
        "shandeId": "SD-60",
        "chineseName": "方萱",
        "firstName": "Crystal",
        "lastName": "Fung",
        "gender": "F",
        "dob": "2020-06-04",
        "pictureLocation": photo_url + "child_img/60.png",
        "base64Data": "assets/img/childPic/60.png"
      },
      {
        "id": "SDC23044",
        "shandeId": "SD-61",
        "chineseName": "段瑾瑄",
        "firstName": "Duan",
        "lastName": "Jane Xian",
        "gender": "F",
        "dob": "2014-03-11",
        "pictureLocation": photo_url + "child_img/61.png",
        "base64Data": "assets/img/childPic/61.jpg"
      },
      {
        "id": "SDC23045",
        "shandeId": "SD-62",
        "chineseName": "谢巧恩",
        "firstName": "Chia",
        "lastName": "Qiao En",
        "gender": "F",
        "dob": "2022-04-15",
        "pictureLocation": photo_url + "child_img/62.png",
        "base64Data": "assets/img/childPic/62.jpg"
      },
      {
        "id": "SDC23046",
        "shandeId": "SD-63",
        "chineseName": "杨玉心",
        "firstName": "Yong",
        "lastName": "Yu Xin",
        "gender": "F",
        "dob": "2017-07-21",
        "pictureLocation": photo_url + "child_img/63.png",
        "base64Data": "assets/img/childPic/63.jpg"
      },
      {
        "id": "SDC24001",
        "shandeId": "SD-64",
        "chineseName": "张思恩",
        "firstName": "Cadie Teow",
        "lastName": "See En",
        "gender": "F",
        "dob": "2017-10-26",
        "pictureLocation": photo_url + "child_img/64.jpg",
        "base64Data": "assets/img/childPic/64.jpg"
      },
      {
        "id": "SDC24002",
        "shandeId": "SD-65",
        "chineseName": "张思玫",
        "firstName": "Vera Teow",
        "lastName": "See Mei",
        "gender": "F",
        "dob": "2020-11-13",
        "pictureLocation": photo_url + "child_img/65.jpg",
        "base64Data": "assets/img/childPic/65.jpg"
      },
      {
        "id": "SDC24003",
        "shandeId": "SD-66",
        "chineseName": "",
        "firstName": "Dinishashni A/P Karthikosoro",
        "lastName": "Naiko",
        "gender": "F",
        "dob": "2017-07-25",
        "pictureLocation": photo_url + "child_img/66.jpg",
        "base64Data": "assets/img/childPic/66.jpg"
      },
      {
        "id": "SDC24004",
        "shandeId": "SD-67",
        "chineseName": "黄梓嘉",
        "firstName": "Simon Ng",
        "lastName": "Zii Jia",
        "gender": "M",
        "dob": "2023-03-01",
        "pictureLocation": photo_url + "child_img/67.jpg",
        "base64Data": "assets/img/childPic/67.jpg"
      },
      {
        "id": "SDC24005",
        "shandeId": "SD-68",
        "chineseName": "陈凯威",
        "firstName": "William Tan",
        "lastName": "Kai Wei",
        "gender": "M",
        "dob": "2021-05-07",
        "pictureLocation": photo_url + "child_img/68.jpg",
        "base64Data": "assets/img/childPic/68.jpg"
      },
      {
        "id": "SDC24006",
        "shandeId": "SD-69",
        "chineseName": "吴凯汶",
        "firstName": "Kelvin Ngo",
        "lastName": "Kai Wen",
        "gender": "M",
        "dob": "2018-11-06",
        "pictureLocation": photo_url + "child_img/69.jpg",
        "base64Data": "assets/img/childPic/69.jpg"
      },
      {
        "id": "SDC24007",
        "shandeId": "SD-70",
        "chineseName": "吴凯杰",
        "firstName": "Ngo",
        "lastName": "Kai Jie",
        "gender": "M",
        "dob": "2017-04-01",
        "pictureLocation": photo_url + "child_img/70.jpg",
        "base64Data": "assets/img/childPic/70.jpg"
      },
      {
        "id": "SDC24008",
        "shandeId": "SD-71",
        "chineseName": "吴凯晴",
        "firstName": "Ngo",
        "lastName": "Kai Qing",
        "gender": "F",
        "dob": "2017-04-01",
        "pictureLocation": photo_url + "child_img/71.jpg",
        "base64Data": "assets/img/childPic/71.jpg"
      },
      {
        "id": "SDC24009",
        "shandeId": "SD-72",
        "chineseName": "林晨毅",
        "firstName": "Aldric Lim",
        "lastName": "Chen Yi",
        "gender": "M",
        "dob": "2024-11-02",
        "pictureLocation": photo_url + "child_img/72.jpeg",
        "base64Data": "assets/img/childPic/72.jpeg"
      },
      {
        "id": "SDC25001",
        "shandeId": "SD-73",
        "chineseName": "",
        "firstName": "",
        "lastName": "Shuruthi",
        "gender": "F",
        "dob": "2010-03-14",
        "pictureLocation": photo_url + "child_img/73.jpg",
        "base64Data": "assets/img/childPic/73.jpg"
      },
      {
        "id": "SDC25002",
        "shandeId": "SD-74",
        "chineseName": "",
        "firstName": "",
        "lastName": "Swaathi",
        "gender": "F",
        "dob": "2012-12-21",
        "pictureLocation": photo_url + "child_img/74.jpg",
        "base64Data": "assets/img/childPic/74.jpg"
      },
    ]
    return childData;
}

export function childrenLeftAfter2022() {
  const childData = [
    {
      "id": "SDC23038",
      "shandeId": "SD-50",
      "chineseName": "陈沛恩",
      "firstName": "Chan",
      "lastName": "Pei Eng",
      "gender": "F",
      "dob": "2006-03-06",
      "pictureLocation": photo_url + "child_img/50.png",
      "base64Data": "assets/img/childPic/50.png"
    },
    {
      "id": "SDC23002",
      "shandeId": "SD-02",
      "chineseName": "吴志贤",
      "firstName": "Goh",
      "lastName": "Zhi Xian",
      "gender": "M",
      "dob": "2019-06-23",
      "pictureLocation": photo_url + "child_img/2.png",
      "base64Data": "assets/img/childPic/2.png"
    },
    {
      "id": "SDC23004",
      "shandeId": "SD-04",
      "chineseName": "吴志康",
      "firstName": "Goh",
      "lastName": "Zhi Kang",
      "gender": "M",
      "dob": "2017-05-12",
      "pictureLocation": photo_url + "child_img/4.png",
      "base64Data": "assets/img/childPic/4.png"
    },
    {
      "id": "SDC23005",
      "shandeId": "SD-06",
      "chineseName": "吴志扬",
      "firstName": "Goh",
      "lastName": "Zhi Yang",
      "gender": "M",
      "dob": "2016-01-10",
      "pictureLocation": photo_url + "child_img/6.png",
      "base64Data": "assets/img/childPic/6.png"
    },
    {
      "id": "SDC23014",
      "shandeId": "SD-18",
      "chineseName": "吴卉姿",
      "firstName": "Goh",
      "lastName": "Hui Zi",
      "gender": "F",
      "dob": "2013-10-29",
      "pictureLocation": photo_url + "child_img/18.png",
      "base64Data": "assets/img/childPic/18.png"
    },
    {
      "id": "SDC23036",
      "shandeId": "SD-47",
      "chineseName": "邓长隆",
      "firstName": "Daniel Teng",
      "lastName": "Cheong Long",
      "gender": "M",
      "dob": "2007-06-22",
      "pictureLocation": photo_url + "child_img/47.png",
      "base64Data": "assets/img/childPic/47.png"
    },
    {
      "id": "SDC23037",
      "shandeId": "SD-48",
      "chineseName": "张慧佩",
      "firstName": "Keong",
      "lastName": "Hui Pei",
      "gender": "F",
      "dob": "2007-03-04",
      "pictureLocation": photo_url + "child_img/48.png",
      "base64Data": "assets/img/childPic/48.png"
    },
  ]
  return childData;
}